import React, {useState} from 'react';
import {Layout, Menu} from 'antd';
import {CalendarOutlined, HomeOutlined, MoneyCollectFilled, UserAddOutlined} from '@ant-design/icons';
import {BrowserRouter as Router, Link, Navigate, Route, Routes, useLocation, useParams} from 'react-router-dom';
import {BookingService} from './services/bookings/BookingService';
import {BookingServiceProvider} from './services/bookings/BookingServiceProvider';
import {MemberService} from './services/members/MemberService';
import {MemberServiceProvider} from './services/members/MemberServiceProvider';
import NewMemberCustomForm from './components/membership/NewMemberCustomForm';
import {PaymentService} from './services/payments/PaymentService';
import {PaymentServiceProvider} from './services/payments/PaymentServiceProvider';
import {HealthQuestionnaireService} from './services/members/health/HealthQuestionnaireService';
import {HealthServiceProvider} from './services/members/health/MemberServiceProvider';
import HealthForm from './components/membership/health/HealthForm';
import {withAuthenticationRequired, useAuth0} from '@auth0/auth0-react';
import Auth0ProviderWithConfig from './components/login/Auth0ProviderWithConfig';
import DirectDebitManagement from "./components/directdebit/DirectDebitManagement";
import DirectDebitRunManager from './components/directdebit/DirectDebitRunManager';
import SquashCourtBookingScheduleV2 from "./components/bookings/SquashCourtBookingScheduleV2";
import MemberProfileScreen from "./components/membership/MemberProfileScreen";

const {Header, Sider, Content} = Layout;

const bookingService = new BookingService();
const memberService = new MemberService();
const paymentService = new PaymentService();
const healthQuestionnaireService = new HealthQuestionnaireService();

const HealthFormWrapper: React.FC = () => {
    const {membershipId} = useParams<{ membershipId: string }>();

    if (!membershipId) {
        return <Navigate to="/error" replace/>;
    }

    return <HealthForm membershipId={membershipId}/>;
};

const LayoutWithRouter: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const {logout} = useAuth0();

    const onCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };

    const handleLogout = () => {
        logout({logoutParams: {returnTo: window.location.origin}});
    };

    const menuItems = [
        {key: "/home", icon: <HomeOutlined/>, label: <Link to="/home">Home</Link>},
        {key: "/bookings/facilities", icon: <CalendarOutlined/>, label: <Link to="/bookings/facilities">Bookings</Link>},
        {key: "/newmember", icon: <UserAddOutlined/>, label: <Link to="/newmember">New Member</Link>},
        {
            key: "members",
            icon: <UserAddOutlined/>,
            label: "Members",
            children: [
                {key: "/profiles", icon: <UserAddOutlined/>, label: <Link to="/profiles">Manage Members</Link>}
            ],
        },
        {
            key: "directdebits",
            icon: <MoneyCollectFilled/>,
            label: "Direct Debit",
            children: [
                {
                    key: "/directdebit",
                    icon: <MoneyCollectFilled/>,
                    label: <Link to="/directdebit">Member Direct Debits</Link>
                },
                {
                    key: "/directdebit/runs",
                    icon: <MoneyCollectFilled/>,
                    label: <Link to="/directdebit/runs">Direct Debit Runs</Link>
                },
            ],
        },
        {key: "logout", label: "Logout", onClick: handleLogout},
    ];



    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
                style={{background: '#1C2143'}}
                width={250}
            >
                <div style={{
                    padding: '16px',
                    textAlign: 'center',
                    transition: 'all 0.2s'
                }}>
                    <img
                        src="/ClubLogo.jpg"
                        alt="Club Logo"
                        style={{
                            height: collapsed ? '32px' : '64px',
                            maxWidth: '100%',
                            transition: 'all 0.2s'
                        }}
                    />
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    selectedKeys={[location.pathname]}
                    style={{paddingTop: '60px', background: '#1C2143'}}
                    items={menuItems}
                />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{
                    padding: '0 16px',
                    background: '#1C2143',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                </Header>
                <Content className="site-layout-background" style={{margin: '24px 16px', padding: 24, minHeight: 280}}>
                    <BookingServiceProvider service={bookingService}>
                        <MemberServiceProvider service={memberService}>
                            <PaymentServiceProvider service={paymentService}>
                                <HealthServiceProvider service={healthQuestionnaireService}>
                                    <Routes>
                                        <Route path="/bookings/facilities" element={<SquashCourtBookingScheduleV2 />}/>
                                        <Route path="/profiles" element={<MemberProfileScreen/>}/>
                                        <Route path="/newmember" element={<NewMemberCustomForm/>}/>
                                        <Route path="/members/healthform/:membershipId" element={<HealthFormWrapper/>}/>
                                        <Route path="/directdebit" element={<DirectDebitManagement/>}/>
                                        <Route path="/directdebit/runs" element={<DirectDebitRunManager/>}/>
                                    </Routes>
                                </HealthServiceProvider>
                            </PaymentServiceProvider>
                        </MemberServiceProvider>
                    </BookingServiceProvider>
                </Content>
            </Layout>
        </Layout>
    );
};

const ProtectedLayout = withAuthenticationRequired(LayoutWithRouter);

const Auth0ErrorHandler: React.FC = () => {
    const {error} = useAuth0();

    if (error) {
        return (
            <div>
                <h1>An error occurred</h1>
                <p>{error.message}</p>
            </div>
        );
    }

    return null;
};

const styles = `
        .ant-menu-sub.ant-menu-inline {
            background: #161B38 !important;
        }
        .ant-menu-item-selected {
            background-color: #2A305C !important;
        }
    `;

const App: React.FC = () => {
    return (
        <>
            <style>{styles}</style>
            <Auth0ProviderWithConfig>
                <Router>
                    <Auth0ErrorHandler/>
                    <ProtectedLayout/>
                </Router>
            </Auth0ProviderWithConfig>
        </>
    );
};

export default App;