import HealthFormSubmissionRequest from "../../../requests/member/health/HealthFormSubmissionRequest";

export class HealthQuestionnaireService {
    private readonly clubbookingApiUrl: string;

    constructor() {
        this.clubbookingApiUrl = process.env.REACT_APP_CLUBBOOKING_API_URL || '';
    }

    async save(data: HealthFormSubmissionRequest): Promise<void> {
        // This is a stub. Replace this with actual API call when ready.
        console.log('Saving health questionnaire for member: ', data.membershipId, ' - ', data);
        console.log('URL: ', `${this.clubbookingApiUrl}/healthform/submit`);
        const response = await fetch(`${this.clubbookingApiUrl}/healthform/submit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    }
};