import {Member} from "../../model/members/Member";
import {CreateMemberRequest} from "../../requests/member/CreateMemberRequest";
import {UpdateMemberRequest} from "../../requests/member/UpdateMemberRequest";
import {MemberProfile} from "../../model/members/MemberProfile";

export class MemberService {
    private readonly clubbookingApiUrl: string;

    constructor() {
        this.clubbookingApiUrl = process.env.REACT_APP_CLUBBOOKING_API_URL || '';
    }

    async getMembers(dataSource: string): Promise<Member[]> {
        console.log('Requesting members for data source ')
        const response = await fetch(`${this.clubbookingApiUrl}/members`);
        console.log('Response is here', response);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json() as Member[];
    }

    async getMemberProfiles(): Promise<MemberProfile[]> {
        console.log('Requesting member profiles');
        const response = await fetch(`${this.clubbookingApiUrl}/members`);
        console.log('Response is here', response);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json() as MemberProfile[];
    }

    async create(request: CreateMemberRequest): Promise<MemberProfile> {
        console.log('Creating member:', request);
        const response = await fetch(`${this.clubbookingApiUrl}/members/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        });

        console.log('Creating member: ', request)

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json() as MemberProfile;
    }

    async update(request: UpdateMemberRequest): Promise<void> {
        console.log('Updating member:', request);
        const response = await fetch(`${this.clubbookingApiUrl}/members/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    }

}