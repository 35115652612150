import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Modal,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface Booking {
    id: string;
    courtId: number;
    startTime: string;
    endTime: string;
    playerName: string;
}

const courts = ['Court 1', 'Court 2', 'Court 3', 'Court 4'];
const startTime = dayjs().set('hour', 8).set('minute', 0);
const endTime = dayjs().set('hour', 22).set('minute', 0);

const generateTimeSlots = () => {
    const slots = [];
    let currentTime = startTime;
    while (currentTime.isBefore(endTime)) {
        slots.push(currentTime.format('HH:mm'));
        currentTime = currentTime.add(30, 'minute');
    }
    return slots;
};

const timeSlots = generateTimeSlots();

const SquashCourtBookingScheduleV2: React.FC = () => {
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState<{ time: Dayjs; courtId: number } | null>(null);
    const [newBooking, setNewBooking] = useState<Partial<Booking>>({});
    const [duration, setDuration] = useState<30 | 60>(30);

    useEffect(() => {
        // Fetch bookings from your API here
        // For this example, we'll use mock data
        const mockBookings: Booking[] = [
            { id: '1', courtId: 1, startTime: '09:00', endTime: '10:00', playerName: 'Alice' },
            { id: '2', courtId: 2, startTime: '10:30', endTime: '11:00', playerName: 'Bob' },
            // Add more mock bookings as needed
        ];
        setBookings(mockBookings);
    }, []);

    const handleCellClick = (time: string, courtId: number) => {
        setSelectedSlot({ time: dayjs(time, 'HH:mm'), courtId });
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedSlot(null);
        setNewBooking({});
        setDuration(30);
    };

    const handleBookingSubmit = () => {
        if (selectedSlot && newBooking.playerName) {
            const booking: Booking = {
                id: Date.now().toString(), // Use a proper ID in production
                courtId: selectedSlot.courtId,
                startTime: selectedSlot.time.format('HH:mm'),
                endTime: selectedSlot.time.add(duration, 'minute').format('HH:mm'),
                playerName: newBooking.playerName
            };
            setBookings([...bookings, booking]);
            handleCloseModal();
        }
    };

    const isSlotBooked = (time: string, courtId: number): Booking | undefined => {
        return bookings.find(booking =>
            booking.courtId === courtId &&
            booking.startTime <= time &&
            booking.endTime > time
        );
    };

    const getBookingRowSpan = (booking: Booking): number => {
        const start = dayjs(booking.startTime, 'HH:mm');
        const end = dayjs(booking.endTime, 'HH:mm');
        return end.diff(start, 'minute') / 30;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Time</TableCell>
                            {courts.map((court, index) => (
                                <TableCell key={index} align="center">{court}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {timeSlots.map((time, index) => (
                            <TableRow key={time}>
                                <TableCell component="th" scope="row">
                                    {time}
                                </TableCell>
                                {courts.map((_, courtId) => {
                                    const booking = isSlotBooked(time, courtId + 1);
                                    if (booking) {
                                        if (booking.startTime === time) {
                                            const rowSpan = getBookingRowSpan(booking);
                                            return (
                                                <TableCell
                                                    key={courtId}
                                                    align="center"
                                                    rowSpan={rowSpan}
                                                    sx={{
                                                        backgroundColor: 'error.light',
                                                        color: 'error.contrastText',
                                                        verticalAlign: 'top',
                                                        padding: 1
                                                    }}
                                                >
                                                    <Typography variant="body2">{booking.playerName}</Typography>
                                                    <Typography variant="caption">{`${booking.startTime} - ${booking.endTime}`}</Typography>
                                                </TableCell>
                                            );
                                        }
                                        return null; // This cell is part of a rowspan, so we don't render it
                                    }
                                    return (
                                        <TableCell
                                            key={courtId}
                                            align="center"
                                            onClick={() => handleCellClick(time, courtId + 1)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <Button variant="outlined" color="primary" size="small">
                                                Available
                                            </Button>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="booking-modal-title"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="booking-modal-title" variant="h6" component="h2" gutterBottom>
                        New Booking
                    </Typography>
                    <TimePicker
                        label="Start Time"
                        value={selectedSlot?.time || null}
                        readOnly
                        sx={{ mb: 2, width: '100%' }}
                    />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="duration-label">Duration</InputLabel>
                        <Select
                            labelId="duration-label"
                            value={duration}
                            label="Duration"
                            onChange={(e) => setDuration(e.target.value as 30 | 60)}
                        >
                            <MenuItem value={30}>30 minutes</MenuItem>
                            <MenuItem value={60}>60 minutes</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Player Name"
                        value={newBooking.playerName || ''}
                        onChange={(e) => setNewBooking({ ...newBooking, playerName: e.target.value })}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <Button onClick={handleBookingSubmit} variant="contained" color="primary">
                        Book Court
                    </Button>
                </Box>
            </Modal>
        </LocalizationProvider>
    );
};

export default SquashCourtBookingScheduleV2;