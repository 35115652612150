import React from 'react';
import {PaymentService} from "./PaymentService";

interface PaymentServiceProviderProps {
    service: PaymentService;
    children: React.ReactNode;  // Add this line
}

// Create a context for the booking service
export const PaymentServiceContext = React.createContext<PaymentService | undefined>(undefined);

// Create a provider component for the booking service
export const PaymentServiceProvider: React.FC<PaymentServiceProviderProps> = ({ children, service }) => {
    return (
        <PaymentServiceContext.Provider value={service}>
            {children}
        </PaymentServiceContext.Provider>
    );
};

// Create a hook to use the payment service
export const usePaymentService = (): PaymentService => {
    const context = React.useContext(PaymentServiceContext);
    if (context === undefined) {
        throw new Error('usePaymentService must be used within a PaymentServiceProvider');
    }
    return context;
};