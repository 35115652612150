import {CreateDirectDebitRequest} from "../../requests/payments/CreateDirectDebitRequest";
import {CreateDirectDebitFileRequest} from "../../requests/payments/CreateDirectDebitFileRequest";
import {DirectDebitFile} from "../../model/payments/DirectDebitFile";
import {DirectDebitRun} from "../../model/payments/DirectDebitRun";
import {DirectDebitMemberProfile} from "../../model/payments/DirectDebitMemberProfile";
import { ProcessDirectDebitRejectionRequest } from "../../requests/payments/ProcessDirectDebitRejectionRequest";

export class PaymentService {
    private readonly clubbookingApiUrl: string;

    constructor() {
        this.clubbookingApiUrl = process.env.REACT_APP_CLUBBOOKING_API_URL || '';
    }

    async createDirectDebit(request: CreateDirectDebitRequest): Promise<void> {
        console.log('Creating direct debit:', request);
        const response = await fetch(`${this.clubbookingApiUrl}/payments/create/instruction`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    }

    async createDirectDebitFile(request: CreateDirectDebitFileRequest): Promise<Blob> {
        console.log('Creating direct debit file for', request.directDebitPaymentRequests.length, 'members on', request.paymentDate.toDateString());
        const response = await fetch(`${this.clubbookingApiUrl}/payments/create/directdebitfile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const directDebitFile = await response.json() as DirectDebitFile;
        const fileContent = directDebitFile.directDebitLines.join('\n');
        return new Blob([fileContent], { type: 'text/plain' });
    }

    async getDirectDebitRuns(): Promise<DirectDebitRun[]> {
        console.log('Fetching direct debit runs');
        const response = await fetch(`${this.clubbookingApiUrl}/payments/directdebit/runs`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const directDebitRuns = await response.json() as DirectDebitRun[];
        return directDebitRuns;
    }

    async getActiveDirectDebitMembers(): Promise<DirectDebitMemberProfile[]> {
        console.log('Fetching active direct debit members');
        const response = await fetch(`${this.clubbookingApiUrl}/payments/directdebit/active`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    }

    async processDirectDebitRejection(request: ProcessDirectDebitRejectionRequest): Promise<void> {
        console.log('Processing direct debit rejection:', request);
        const response = await fetch(`${this.clubbookingApiUrl}/payments/directdebit/rejections`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    }
}