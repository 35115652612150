import React from 'react';
import { Form, Radio, Checkbox, Input, Button, Typography, Space, DatePicker, message } from 'antd';
import {useHealthService} from "../../../services/members/health/MemberServiceProvider";
import HealthFormSubmissionRequest from "../../../requests/member/health/HealthFormSubmissionRequest";

const { Title, Paragraph } = Typography;


interface HealthFormProps {
    membershipId: string;
}

const HealthForm: React.FC<HealthFormProps> = ({ membershipId }) => {
    const healthQuestionnaireService = useHealthService();
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        try {
            const request: HealthFormSubmissionRequest = {
                membershipId,
                answers: [
                    { question: 'heartCondition', answer: values.heartCondition },
                    { question: 'chestPain', answer: values.chestPain },
                    { question: 'chestPainLastMonth', answer: values.chestPainLastMonth },
                    { question: 'balanceConsciousness', answer: values.balanceConsciousness },
                    { question: 'boneJointProblem', answer: values.boneJointProblem },
                    { question: 'bloodPressureMedication', answer: values.bloodPressureMedication },
                    { question: 'medicalConditions', answer: values.medicalConditions.join('|') },
                    { question: 'recentSurgery', answer: values.recentSurgery },
                    { question: 'pregnant', answer: values.pregnant },
                    { question: 'recentBaby', answer: values.recentBaby },
                    { question: 'currentlySick', answer: values.currentlySick },
                    { question: 'agreement', answer: values.agreement.toString() },
                    { question: 'printName', answer: values.printName },
                    { question: 'date', answer: values.date },
                ],
            }
            await healthQuestionnaireService.save(request);
            message.success('Health questionnaire submitted successfully');
            // Redirect or show success message
        } catch (error) {
            message.error('Failed to submit health questionnaire');
            console.error('Error submitting health questionnaire:', error);
        }
    };

    return (
        <Form form={form} onFinish={onFinish} layout="vertical">
            <Title level={2}>PARQ</Title>
            <Paragraph>
                Please read the questions carefully and answer each question honestly and to the best of your ability.
                Please do this by ticking the appropriate box or adding information if necessary. Your responses will of course
                be kept in the strictest confidence
            </Paragraph>

            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Form.Item
                    name="heartCondition"
                    label="Has your doctor ever said that you have a heart condition and that you should only do physical activity recommended by a doctor?"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="chestPain"
                    label="Do you feel any unusual pain in your chest when you do physical activity?"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="chestPainLastMonth"
                    label="In the past month, have you had chest pain when you were not doing physical activity?"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="balanceConsciousness"
                    label="Do you lose your balance because of dizziness or do you ever lose consciousness?"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="boneJointProblem"
                    label="Do you have a bone or joint problem (for example, back, knee or hip) that could be made worse by change in your physical activity?"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="bloodPressureMedication"
                    label="Is your doctor currently prescribing drugs (for example, water pills) for your blood pressure or heart condition?"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="medicalConditions"
                    label="Have you ever been diagnosed with:"
                    rules={[{ required: true, message: 'Please select at least one option' }]}
                >
                    <Checkbox.Group>
                        <Space direction="vertical">
                            <Checkbox value="diabetes">Diabetes</Checkbox>
                            <Checkbox value="epilepsy">Epilepsy</Checkbox>
                            <Checkbox value="asthma">Asthma</Checkbox>
                            <Checkbox value="other">Other Problems</Checkbox>
                            <Checkbox value="none">None</Checkbox>
                        </Space>
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item
                    name="recentSurgery"
                    label="In the past year have you had any other major illness or surgery?"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="pregnant"
                    label="Are you pregnant?"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="recentBaby"
                    label="Have you recently had a baby?"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="currentlySick"
                    label="Are you feeling unwell at present due to a cold etc?"
                    rules={[{ required: true, message: 'Please select an option' }]}
                >
                    <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Paragraph>
                    If you have answered YES to one or more questions we may need to contact your doctor before you
                    can start to exercise. If your health changes so that you may answer YES to any of there questions,
                    tell a member of the gym staff as soon as possible.
                </Paragraph>

                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[{ required: true, message: 'Please confirm you have read and understood the questionnaire' }]}
                >
                    <Checkbox>
                        I have read, understood and completed this questionnaire. Any questions that I had were answered
                        to my full satisfaction. I confirm that I was shown how to use the equipment safely and effectively
                        during my induction
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    name="printName"
                    label="Print Name:"
                    rules={[{ required: true, message: 'Please enter your name' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="date"
                    label="Date:"
                    rules={[{ required: true, message: 'Please select a date' }]}
                >
                    <DatePicker />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit Health Form
                    </Button>
                </Form.Item>
            </Space>
        </Form>
    );
};

export default HealthForm;