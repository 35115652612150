import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Space, notification, AutoComplete } from 'antd';
import { useMemberService } from '../../services/members/MemberServiceProvider';
import { MemberProfile } from '../../model/members/MemberProfile';
import { UpdateMemberRequest } from '../../requests/member/UpdateMemberRequest';

const { Item } = Form;

const MemberProfileScreen: React.FC = () => {
    const memberService = useMemberService();
    const [memberProfiles, setMemberProfiles] = useState<MemberProfile[]>([]);
    const [selectedMember, setSelectedMember] = useState<MemberProfile | null>(null);
    const [searchOptions, setSearchOptions] = useState<{ value: string; label: React.ReactNode }[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [form] = Form.useForm();
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

    useEffect(() => {
        fetchMemberProfiles();
    }, [memberService]);

    const fetchMemberProfiles = async () => {
        try {
            const profiles = await memberService.getMemberProfiles();
            setMemberProfiles(profiles);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to fetch member profiles',
            });
        }
    };

    const handleSearch = (value: string) => {
        setSearchValue(value);
        if (!value) {
            setSearchOptions([]);
            return;
        }

        const uniqueProfiles = new Set<string>();
        const matchingProfiles = memberProfiles.filter(profile => {
            const key = `${profile.membershipId}|${profile.emailAddress}`;
            if (uniqueProfiles.has(key)) {
                return false;
            }
            uniqueProfiles.add(key);
            return profile.name.toLowerCase().includes(value.toLowerCase()) ||
                profile.membershipId.includes(value) ||
                profile.emailAddress.toLowerCase().includes(value.toLowerCase());
        });

        const options = matchingProfiles.map(profile => ({
            value: `${profile.membershipId}|${profile.emailAddress}`,
            label: (
                <div>
                    <div>{profile.name}</div>
                    <div style={{ fontSize: '0.8em', color: '#888' }}>
                        {profile.membershipId} | {profile.emailAddress}
                    </div>
                </div>
            ),
        }));

        setSearchOptions(options);
    };

    const handleSelect = (value: string) => {
        const [membershipId, email] = value.split('|');
        const selectedProfile = memberProfiles.find(
            profile => profile.membershipId === membershipId && profile.emailAddress === email
        );
        if (selectedProfile) {
            setSelectedMember(selectedProfile);
            form.setFieldsValue(selectedProfile);
            setSearchValue(selectedProfile.name);
            setSearchOptions([]);
        }
    };

    const handleRefresh = () => {
        fetchMemberProfiles();
        setSearchValue('');
        setSearchOptions([]);
        form.resetFields();
    };

    const handleFormChange = () => {
        setIsSaveButtonDisabled(false);
    };

    const handleSave = async () => {
        if (!selectedMember) return;

        try {
            const values = form.getFieldsValue();
            const updateRequest: UpdateMemberRequest = {
                ...selectedMember,
                ...values,
            };
            await memberService.update(updateRequest);
            notification.success({
                message: 'Success',
                description: 'Member profile updated successfully',
            });
            setIsSaveButtonDisabled(true);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to update member profile',
            });
        }
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Space>
                <AutoComplete
                    style={{ width: 300 }}
                    options={searchOptions}
                    onSearch={handleSearch}
                    onSelect={handleSelect}
                    value={searchValue}
                    onChange={setSearchValue}
                    placeholder="Search by name, membership number, or email"
                />
                <Button onClick={handleRefresh}>Refresh</Button>
            </Space>
            {selectedMember && (
                <Form form={form} onValuesChange={handleFormChange} layout="vertical" initialValues={selectedMember}>
                    <Item name="name" label="Name">
                        <Input />
                    </Item>
                    <Item name="username" label="Username">
                        <Input />
                    </Item>
                    <Item name="dateOfBirth" label="Date of Birth">
                        <Input />
                    </Item>
                    <Item name="emailAddress" label="Email Address">
                        <Input />
                    </Item>
                    <Item name="addressLine1" label="Address Line 1">
                        <Input />
                    </Item>
                    <Item name="addressLine2" label="Address Line 2">
                        <Input />
                    </Item>
                    <Item name="townCity" label="Town/City">
                        <Input />
                    </Item>
                    <Item name="countyState" label="County/State">
                        <Input />
                    </Item>
                    <Item name="postZipCode" label="Post/Zip Code">
                        <Input />
                    </Item>
                    <Item name="phoneNumber" label="Phone Number">
                        <Input type="text" />
                    </Item>
                    {/*<Item name="specialOffers" label="Special Offers">*/}
                    {/*    <Input />*/}
                    {/*</Item>*/}
                    <Button type="primary" onClick={handleSave} disabled={isSaveButtonDisabled}>
                        Save
                    </Button>
                </Form>
            )}
        </Space>
    );
};

export default MemberProfileScreen;