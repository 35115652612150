import React, { useState, useEffect, useCallback } from 'react';
import {Form, Input, Button, Radio, DatePicker, notification, Typography, Divider, Select} from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { RightOutlined } from '@ant-design/icons';
import { useMemberService } from '../../services/members/MemberServiceProvider';
import { CreateMemberRequest } from '../../requests/member/CreateMemberRequest';
import {usePaymentService} from "../../services/payments/PaymentServiceProvider";
import {CreateDirectDebitRequest} from "../../requests/payments/CreateDirectDebitRequest";
import {MemberProfile} from "../../model/members/MemberProfile";
import {useNavigate} from "react-router-dom";
import {MembershipCategory} from "../../model/members/MembershipCategory";
import {Member} from "../../model/members/Member";

const { Item } = Form;
const { Title } = Typography;
const {Option} = Select;
const { Text } = Typography;

// Custom masked input for sort code
const SortCodeInput: React.FC<{
    value?: string;
    onChange?: (value: string) => void;
    // validateForm: () => void;
}> = ({ value = '', onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value.replace(/[^\d]/g, '');
        const formattedValue = inputValue
            .slice(0, 6)
            .replace(/(\d{2})(?=\d)/g, '$1-');
        onChange?.(formattedValue);
        // validateForm();
    };

    return (
        <Input
            value={value}
            onChange={handleChange}
            placeholder="11-22-33"
            maxLength={8}
        />
    );
};

// Custom input for account number (numeric only)
const AccountNumberInput: React.FC<{
    value?: string;
    onChange?: (value: string) => void;
    // validateForm: () => void;
}> = ({ value = '', onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numericValue = e.target.value.replace(/[^\d]/g, '');
        onChange?.(numericValue);
        // validateForm();
    };

    return (
        <Input
            value={value}
            onChange={handleChange}
            placeholder="12345678"
            maxLength={8}
        />
    );
};



const NewMemberCustomForm: React.FC = () => {
    const navigate = useNavigate();
    const memberService = useMemberService();
    const paymentService = usePaymentService();
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const [form] = Form.useForm();
    const [otherSalutation, setOtherSalutation] = useState('');
    const [membershipCategory, setMembershipCategory] = useState<MembershipCategory | null>(null);

    const validateForm = useCallback(async () => {
        try {
            await form.validateFields();
            setIsSubmitButtonDisabled(false);
        } catch {
            setIsSubmitButtonDisabled(true);
        }
    }, [form]);

    // useEffect(() => {
    //     validateForm();
    // }, [validateForm]);


    const [membershipCategories] = useState<MembershipCategory[]>([
        { paymentFrequency: 'Monthly', name: 'Squash', amount: 33.50 },
        { paymentFrequency: 'Monthly', name: 'Corporate', amount: 34.00 },
        { paymentFrequency: 'Monthly', name: 'Student', amount: 43.50 },
        { paymentFrequency: 'Monthly', name: 'Fitness', amount: 49.50 },
        { paymentFrequency: 'Monthly', name: 'Visitor', amount: 55.00 },
        { paymentFrequency: 'Monthly', name: 'Duo', amount: 88.00 },
    ]);
    const [proRataAmount, setProRataAmount] = useState<number | null>(null);

    const calculateProRataAmount = (price: number) => {
        const today = new Date();
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const daysInMonth = endOfMonth.getDate();
        const remainingDays = daysInMonth - today.getDate() + 1;

        let totalAmount;
        if (today.getDate() >= 23) {
            const nextMonthDays = new Date(today.getFullYear(), today.getMonth() + 2, 0).getDate();
            totalAmount = price * (remainingDays / daysInMonth + 1);
        } else {
            totalAmount = price * (remainingDays / daysInMonth);
        }

        console.log('Today:', today);
        console.log('End of month:', endOfMonth);
        console.log('Remaining days:', remainingDays);
        console.log('Days in month:', daysInMonth);
        console.log('Price:', price);
        console.log('Total amount:', totalAmount);

        return parseFloat(totalAmount.toFixed(2));
    };

    const handleMembershipCategoryChange = (value: string) => {
        const category = membershipCategories.find(cat => cat.name === value);
        if (category) {
            const proRata = calculateProRataAmount(category.amount);
            setProRataAmount(proRata);
            form.setFieldsValue({ membershipCategory: value });
            setMembershipCategory(category);
        }
    };

    async function createMember(values: any): Promise<MemberProfile> {
        let request: CreateMemberRequest;
        request = {
            name: `${values.firstName} ${values.surname}`,
            username: values.email,
            countyState: values.countyState,
            dateOfBirth: values.dateOfBirth,
            emailAddress: values.email,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            townCity: values.townCity,
            postZipCode: values.postcode,
            phoneNumber: values.phoneNumber,
            country: values.country,
            specialOffers: values.specialOffers === 'Yes',
            status: 'Active',
            membershipCategory: membershipCategory as MembershipCategory
        };

        try {
            console.log('Creating member from form:', request);
            let member = await memberService.create(request);
            notification.success({
                message: 'Success',
                description: 'Member Created Successfully',
            });
            form.resetFields();

            return member;
        } catch (error) {
            if (error instanceof Error) {
                notification.error({
                    message: 'Error',
                    description: error.message || 'An error occurred while creating the member',
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'An unknown error occurred while creating the member',
                });
            }
        }
        throw new Error('Failed to create member');
    }

    async function createDirectDebit(values: any, member: MemberProfile) {
        const request: CreateDirectDebitRequest = {
            membershipId: member.membershipId,
            accountHolderName: values.accountHolderName,
            bankName: values.bankName,
            accountNumber: values.accountNumber,
            sortCode: values.sortCode.replace(/-/g, '')
        };

        console.log('Creating direct debit:', request);
        try {
            await paymentService.createDirectDebit(request);
            notification.success({
                message: 'Success',
                description: 'Direct Debit Created Successfully',
            });
            form.resetFields();
        } catch (error) {
            if (error instanceof Error) {
                notification.error({
                    message: 'Error',
                    description: error.message || 'An error occurred while saving the Direct Debit details',
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'An unknown error occurred while creating the Direct Debit details',
                });
            }
        }
    }

    const onFinish = async (values: any) => {
        try {
            let member = await createMember(values);
            await createDirectDebit(values, member);

            // Navigate to the health form
            navigate(`/members/healthform/${member.membershipId}`);
        } catch (error) {
            console.error('Error during form submission:', error);
            // You might want to show an error message to the user here
            // For example, using Ant Design's message component:
            // message.error('Failed to create member. Please try again.');
        }
    };

    const capitalizeWords = (value: string) => {
        return value.replace(/\b\w/g, char => char.toUpperCase());
    };

    const handleInputChange = (field: string, capitalize: boolean = false) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;
        if (capitalize) {
            value = capitalizeWords(value);
        }
        form.setFieldsValue({ [field]: value });
        // validateForm();
    };

    const handleSalutationChange = (e: RadioChangeEvent) => {
        if (e.target.value !== 'Other') {
            setOtherSalutation('');
        }
        // validateForm();
    };

    const handleOtherSalutationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setOtherSalutation(value);
        if (value) {
            form.setFieldsValue({ salutation: 'Other' });
        }
        // validateForm();
    };

    return (
        <Form form={form} onFinish={onFinish} layout="vertical" initialValues={{ country: 'United Kingdom' }}>
            <Item
                name="email"
                label="Email Address"
                rules={[{ required: true, type: 'email', message: 'Please enter a valid email address' }]}
            >
                <Input onChange={handleInputChange('email')} />
            </Item>
            <Item name="salutation" label="Salutation" rules={[{ required: true, message: 'Please select a salutation' }]}>
                <Radio.Group onChange={handleSalutationChange}>
                    <Radio value="Mr">Mr</Radio>
                    <Radio value="Mrs">Mrs</Radio>
                    <Radio value="Miss">Miss</Radio>
                    <Radio value="Ms">Ms</Radio>
                    <Radio value="Other">
                        Other: <Input style={{ width: 100 }} value={otherSalutation} onChange={handleOtherSalutationChange} />
                    </Radio>
                </Radio.Group>
            </Item>
            <Item name="firstName" label="First Name" rules={[{ required: true, message: 'Please enter your first name' }]}>
                <Input onChange={handleInputChange('firstName', true)} />
            </Item>
            <Item name="surname" label="Surname" rules={[{ required: true, message: 'Please enter your surname' }]}>
                <Input onChange={handleInputChange('surname', true)} />
            </Item>
            <Item name="addressLine1" label="Address Line 1" rules={[{ required: true, message: 'Please enter your address' }]}>
                <Input onChange={handleInputChange('addressLine1', true)} />
            </Item>
            <Item name="addressLine2" label="Address Line 2">
                <Input onChange={handleInputChange('addressLine2', true)} />
            </Item>
            <Item name="townCity" label="Town/City" rules={[{ required: true, message: 'Please enter your town or city' }]}>
                <Input onChange={handleInputChange('townCity', true)} />
            </Item>
            <Item name="countyState" label="County/State" rules={[{ required: true, message: 'Please enter your county or state' }]}>
                <Input onChange={handleInputChange('countyState', true)} />
            </Item>
            <Item name="postcode" label="Postcode" rules={[{ required: true, message: 'Please enter your postcode' }]}>
                <Input onChange={handleInputChange('postcode')} />
            </Item>
            <Item name="country" label="Country" rules={[{ required: true, message: 'Please enter your country' }]}>
                <Input onChange={handleInputChange('country', true)} />
            </Item>
            <Item
                name="dateOfBirth"
                label="Date of Birth"
                rules={[{ required: true, message: 'Please enter your date of birth' }]}
            >
                <DatePicker format="DD/MM/YYYY"  />
            </Item>
            <Item
                name="phoneNumber"
                label="Phone Number"
                rules={[{ required: true, pattern: /^\d+$/, message: 'Please enter a valid phone number' }]}
            >
                <Input onChange={handleInputChange('phoneNumber')} />
            </Item>
            <Item
                name="specialOffers"
                label="Would you like to receive information about special offers and upcoming events at the club?"
                rules={[{ required: true, message: 'Please select an option' }]}
            >
                <Radio.Group >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                </Radio.Group>
            </Item>

            <Item
                name="membershipCategory"
                label="Membership Category"
                rules={[{ required: true, message: 'Please select a membership category' }]}
            >
                <Select onChange={handleMembershipCategoryChange} placeholder="Select a membership category">
                    {membershipCategories.map((category) => (
                        <Option key={category.name} value={category.name}>
                            {`${category.name} £${category.amount.toFixed(2)}`}
                        </Option>
                    ))}
                </Select>
            </Item>

            {proRataAmount !== null && (
                <Item>
                    <Text strong style={{ fontSize: '18px' }}>
                        Pro-rata amount due today: £{proRataAmount.toFixed(2)}
                    </Text>
                </Item>
            )}

            <Divider />

            <Title level={3}>Direct Debit Setup</Title>

            <Item name="bankName" label="Bank Name" rules={[{ required: true, message: 'Please enter the bank name' }]}>
                <Input onChange={handleInputChange('bankName', true)} />
            </Item>
            <Item name="accountHolderName" label="Name of Account Holder" rules={[{ required: true, message: 'Please enter the account holder name' }]}>
                <Input onChange={handleInputChange('accountHolderName', true)} />
            </Item>
            <Item
                name="sortCode"
                label="Sort Code"
                rules={[
                    { required: true, message: 'Please enter the sort code' },
                    { pattern: /^\d{2}-\d{2}-\d{2}$/, message: 'Please enter a valid sort code in the format 11-22-33' }
                ]}
            >
                <SortCodeInput />
            </Item>
            <Item
                name="accountNumber"
                label="Account Number"
                rules={[
                    { required: true, message: 'Please enter the account number' },
                    { pattern: /^\d{8}$/, message: 'Please enter a valid 8-digit account number' }
                ]}
            >
                <AccountNumberInput />
            </Item>

            <Button type="primary" htmlType="submit" icon={<RightOutlined />} >
                Submit
            </Button>
        </Form>
    );
};

export default NewMemberCustomForm;