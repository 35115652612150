import {EZSchedule} from "../../model/ezfacility/schedules/EZSchedule";
import {EZBooking} from "../../model/ezfacility/bookings/EZBooking";
import {FacilityScheduleForDay} from "../../model/bookings/FacilityScheduleForDay";
import {CreateBookingRequest} from "../../model/bookings/CreateBookingRequest";

export class BookingService {
    private readonly apiUrl: string;
    private readonly clubbookingApiUrl: string;

    constructor() {
        this.apiUrl = process.env.REACT_APP_EZ_FACILITY_API_URL || '';
        this.clubbookingApiUrl = process.env.REACT_APP_CLUBBOOKING_API_URL || '';
    }

    async getSchedules(from: Date, to: Date, facilityType: string): Promise<EZSchedule[]> {
        const fromUTC = from.toISOString().split('T')[0];
        const toUTC = to.toISOString().split('T')[0];

        console.log('API URL is here', this.apiUrl);
        console.log('Requesting schdeules from [', fromUTC, '] to [', toUTC, ']')
        const response = await fetch(`${this.apiUrl}/v2/schedule/1234/${fromUTC}/${toUTC}`);
        console.log('Response is here', response);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Use type assertion to specify the type of the JSON response
        const jsonResponse = await response.json();
        const data = jsonResponse.schedules;
        console.log('Data is here', JSON.stringify(data));
        return data as EZSchedule[];
    }

    async getBookings(from: Date, to: Date): Promise<EZBooking[]> {
        const fromUTC = from.toISOString().split('T')[0].replaceAll('-', '');
        const toUTC = to.toISOString().split('T')[0].replaceAll('-', '');

        console.log('API URL is here', this.apiUrl);
        console.log('Requesting bookings from [', fromUTC, '] to [', toUTC, ']')
        const response = await fetch(`${this.apiUrl}/v2/sessions/bookings/startDate=${fromUTC}/endDate=${toUTC}`);
        // /v2/sessions/bookings/startDate=:startDate/endDate=:endDate
        console.log('Response is here', response);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Use type assertion to specify the type of the JSON response
        const jsonResponse = await response.json();
        const data = jsonResponse.bookings;
        console.log('Data is here', JSON.stringify(data));
        return data as EZBooking[];
    }

    async getBookingsForFacilityType(from: Date, to: Date, facilityType: string): Promise<FacilityScheduleForDay[]> {
        const fromUTC = from.toISOString().split('T')[0];
        const toUTC = to.toISOString().split('T')[0];

        console.log(this.clubbookingApiUrl);
        console.log('Requesting bookings from [', fromUTC, '] to [', toUTC, ']')
        const response = await fetch(`${this.clubbookingApiUrl}/bookings?from=${fromUTC}&to=${toUTC}&facilityType=${facilityType}`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Use type assertion to specify the type of the JSON response
        return await response.json() as FacilityScheduleForDay[];
    }

    async saveBooking(bookingRequest: CreateBookingRequest) {
        console.log('Saving booking at ', `${this.clubbookingApiUrl}/bookings/create`, JSON.stringify(bookingRequest));
        const response = await fetch(`${this.apiUrl}/bookings/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bookingRequest)
        });

        if (!response.ok) {
            throw new Error('Unable to save booking ' + response.statusText);
        }
    }

}