import React from 'react';
import {MemberService} from "./MemberService";

interface MemberServiceProviderProps {
    service: MemberService;
    children: React.ReactNode;  // Add this line
}

// Create a context for the booking service
export const MemberServiceContext = React.createContext<MemberService | undefined>(undefined);

// Create a provider component for the booking service
export const MemberServiceProvider: React.FC<MemberServiceProviderProps> = ({ children, service }) => {
    return (
        <MemberServiceContext.Provider value={service}>
            {children}
        </MemberServiceContext.Provider>
    );
};

// Create a hook to use the booking service
export const useMemberService = (): MemberService => {
    const context = React.useContext(MemberServiceContext);
    if (context === undefined) {
        throw new Error('useMemberService must be used within a MemberServiceProvider');
    }
    return context;
};