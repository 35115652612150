import React from 'react';
import { Auth0Provider, AppState } from '@auth0/auth0-react';

interface Auth0ProviderWithConfigProps {
    children: React.ReactNode;
}

const Auth0ProviderWithConfig: React.FC<Auth0ProviderWithConfigProps> = ({ children }) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI || window.location.origin;

    if (!domain || !clientId) {
        console.error('Auth0 configuration is missing');
        return <div>Error: Auth0 configuration is missing</div>;
    }

    console.log('Auth0 Config:', { domain, clientId, redirectUri });

    const onRedirectCallback = (appState?: AppState) => {
        console.log('Auth0 Redirect Callback:', appState);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithConfig;